import React, {useState, useEffect} from 'react'
import { api } from './config'
import axios from 'axios'

const Dashboard = (props) => {
  const [warrantyList, setWarrantyList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    axios.post(api.getWarranties, {
      page: 1,
      token: getCookie('token')
    }).then(resp => {
      setWarrantyList(resp.data.data.warrantyList)
      setTotalPages(resp.data.data.pageCount)
    }).catch(err => {
      console.log(err.response.status);
      console.log(err)
      if(err.response.status === 401) {
        props.history.push("/");
      }     
    })
  }, [])

  const onRemove = (id) => {

    const confirm = window.confirm("Are you sure you want to remove this warranty? This action is PERMANENT.");
    if(confirm) {
    } else {
      return
    }
    axios.post(api.deleteWarranty, {
      id,
      page: currentPage,
      searchTerm,
      token: getCookie('token')
    }).then(resp => {
      setWarrantyList(resp.data.data.warrantyList)
      setTotalPages(resp.data.data.pageCount)
    }).catch(err => {
      console.log(err.response.status);
      console.log(err)
      if(err.response.status === 401) {
        props.history.push("/");
      }     
    })
    
  }

  const changePage = (page) => {
    setCurrentPage(page)
    axios.post(api.getWarranties, {
      page,
      searchTerm,
      token: getCookie('token')
    }).then(resp => {
      setWarrantyList(resp.data.data.warrantyList)
    }).catch(err => {
      console.log(err.response.status);
      console.log(err)
      if(err.response.status === 401) {
        props.history.push("/");
      }     
    })
  }

  const onSearch = (term = searchTerm) => {
    axios.post(api.getWarranties, {
      page: 1,
      searchTerm: term,
      token: getCookie('token')
    }).then(resp => {
      setWarrantyList(resp.data.data.warrantyList)
      setTotalPages(resp.data.data.pageCount)
    }).catch(err => {
      console.log(err.response.status);
      console.log(err)
      if(err.response.status === 401) {
        props.history.push("/");
      }     
    })
  }

  const onClearSearchTerm = () => {
    setSearchTerm('')
    onSearch('')
  }

  const logout = () => {
    document.cookie = 'token=; Max-Age=-99999999;';  
    props.history.push("/");
  }

  return (
    <section className="section">

      <div className="logout" style={{float: 'right', marginTop: '7px'}} onClick={() => logout()}><strong>Logout</strong></div>
      <div style={{"maxWidth": "1200px", margin: "0 auto"}}>
        
        <nav className="level">
          <div className="level-left">
            <h2 className="title">Warranty List</h2>
          </div>
          <div className="level-right">
            <div className="level-item">
              {searchTerm !== '' ? <a href="#" onClick={() => onClearSearchTerm()}>Clear</a> : '' }
            </div>
            <div className="level-item">
              <div className="field has-addons">
                <p className="control">
                  <input className="input" type="text" placeholder="Search for warranty" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
                </p>
                <p className="control">
                  <button className="button"  onClick={() => onSearch()}>
                    Search
                  </button>
                </p>
              </div>
            </div>
 
          </div>
        </nav>
        <table className="table is-bordered is-striped" style={{width: "100%"}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Product name</th>
              <th>Purchased from</th>
              <th>Purchase date</th>
              <th></th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Product name</th>
              <th>Purchased from</th>
              <th>Purchase date</th>
              <th>Marketing</th>
              <th></th>
            </tr>
          </tfoot>
          <tbody>
            {warrantyList.map((item) => (
              <tr key={item._id}>
                <td>{item.fullName}</td>
                <td>{item.email}</td>
                <td>{item.product}</td>
                <td>{item.purchasedFrom}</td>
                <td>{item.purchaseDate}</td>
                <td>{item.marketing ? 'Yes' : 'No'}</td>
                <td><div className="delete" onClick={() => onRemove(item._id)}></div></td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <div className="pagination-previous" onClick={() => changePage(currentPage - 1)}>Previous</div>
          <div className="pagination-next" onClick={() => changePage(currentPage + 1)}>Next page</div>
          <ul className="pagination-list">
            <GeneratePages total={totalPages} current={currentPage} callback={changePage}/>
          </ul>
        </nav>
      </div>
    </section>
  )
}

const GeneratePages = ({total, current, callback}) => {
  let totalArray = []
  for(let i=1; i<=total; i++) {
    totalArray.push(i)
  }
  return totalArray.map((page, index) => <li key={index}  onClick={() => callback(page)} >
      <div className={page === current ? "pagination-link is-current" : "pagination-link"}>
        {page}
      </div>
    </li>)
}

const getCookie = (name) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

export default Dashboard
