import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./Login.jsx"
import Dashboard from "./Dashboard.jsx"
import logo from './images/logo.png'

function App() {
  return (
    <div className="App">
      <header>
        <img src={logo} alt="Ifootage"/>
      </header>
    
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route component={Login}/>
          </Switch>
        </div>
      </Router>
      
    </div>
  );
}

export default App;
