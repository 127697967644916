import React, {useState} from 'react'
import { api } from './config'
import axios from 'axios'

const Login = (props) => {
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [error, setError] = useState('')

  const handleForm = () => {
    if(user === '' || pass === '') {
      setError('One or more fields are empty')
      return
    }
    axios.post(api.auth, {
      email: user,
      password: pass
    }, {withCredentials: true}).then(response => {
      if(response.data.status === "fail") {
        setError(response.data.error)
      } else if(response.data === "OK") {
        props.history.push("/dashboard");
      }
      
    }).catch(err => {
      // this.errors.push('There was a database error. Please contact support.')
      console.log(err)
    })
  }

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column is-4">
          <h1 className="title">Login</h1>
          <div className="field">
            <label className="label">Username</label>
            <div className="control has-icons-left has-icons-right">
              <input className="input" type="text" value={user} onChange={(e) => {setUser(e.target.value); setError('')}}/>
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control has-icons-left has-icons-right">
              <input className="input" type="password" value={pass} onChange={(e) => {setPass(e.target.value); setError('')}}/>
              <span className="icon is-small is-left">
                <i className="fas fa-key"></i>
              </span>
            </div>
          </div>
          
          {
            error !== '' ? <div className="notification is-danger" style={{padding: '5px 10px'}}>{error}</div> : ''
          }
          
          <button type="button" className="button is-primary" onClick={handleForm}>Login</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
