let mainUrl = ''

if(process.env.NODE_ENV === 'development') {
  mainUrl = 'http://localhost:7777/'
} else {
  mainUrl = 'https://warranty.ifootagegear.com/'
}

const api = {
  auth: mainUrl + 'api/auth',
  checkToken: mainUrl + 'api/auth/check-token',
  postWarranties: mainUrl + 'api/warranties',
  getWarranties: mainUrl + 'api/warranties/get',
  deleteWarranty: mainUrl + 'api/warranties/remove',
}

export { api }
